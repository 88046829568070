import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import { FaTwitter, FaLinkedin } from "react-icons/fa"
import { Container } from "react-bootstrap"

const NetworkingPage = () => (
  <Layout>
    <SEO title="Networking" />
    <Container>
      <h1 style={{ marginTop: "2rem" }}>Contact Us</h1>
      <div style={{ margin: "2rem 0" }}></div>
      <h4>
        <strong>Anthony Hines</strong>, Founder/CEO
      </h4>
      <p>Office: 281-712-8668</p>
      <p>Cell: 407-718-8387</p>
      <h4>
        <strong>Gail Hines</strong>, Business Development Manager
      </h4>
      <p>Office: 281-712-8668</p>
      <p>Cell: 407-592-0512</p>
      <p>
        Email: <a href="mailto:info@asyncnets.com">info@asyncnets.com</a>
      </p>
      {/* <div style={{ display: "flex", marginTop: "1rem" }}>
        <FaTwitter size="1.5rem" style={{ marginRight: "1rem" }} />
        <FaLinkedin size="1.5rem" />
      </div> */}
    </Container>
  </Layout>
)

export default NetworkingPage
